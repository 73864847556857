.products-descroption {
  h1,
  h3 {
    text-align: left !important;
  }
}

.products-descroption > div {
  width: 100% !important;
  margin: 0px !important;
  padding: 0px !important;
}

.products-descroption div {
  width: 100% !important;
}

.products-descroption > div > div {
  width: 100% !important;
  margin: 5% 0px !important;
  //   padding: 10px 10px !important;
}
