@import "../../../styles/Colors.scss";

.breadCrumbs {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span:hover {
    color: $black !important;
  }
  ol {
    align-items: center;
    line-height: 22px !important;
    li {
      font-size: 12px;
      // margin: 2px !important;

      span {
        opacity: 1;
        cursor: pointer;
        font-size: 12px;
        letter-spacing: 0px;
        font-family: "ZemestroStd-Light" !important;
        font-weight: 100 !important;
        color: $breadCrumbsTextColor !important;
        text-transform: capitalize;
      }
    }
    li:last-child {
      .ant-breadcrumb-link {
        span {
          color: $color_grey !important;
        }
      }
    }
  }
}

.ant-breadcrumb {
  li:last-child {
    .ant-breadcrumb-link {
      span {
        color: $color_grey !important;
        font-family: 'ZemestroStd-Light' !important;
        font-weight: 100;
      }
    }
  }
}
