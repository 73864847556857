.enlargeButton {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: white;
    padding: 5px;
    border-radius: 15px;
    opacity: 0.7;
    transition: opacity 0.5s ease, padding 0.5s ease;
}

.enlargeButton:hover {
    opacity: 1;
    background-color: white;
    padding: 5px 10px 5px 5px;
}

.enlargeButton .iconStyle {
    font-size: 24px;
    color: black;
    transition: transform 0.5s ease;
}

.hoverText {
    display: none;
    color: black;
}

.enlargeButton:hover .hoverText {
    display: inline;
}