.SoldOut {
  input {
    height: 38px !important;
    border-radius: 5px !important;
    border: 1px solid #e0e0e0 !important;
  }
  .react-tel-input {
    .form-control,
    input {
      width: 100%;
    }
    .flag-dropdown {
      .flag-dropdown {
      }
    }
  }
}

// Mz Css
.ProductPage .priceCut {
  color: #606060;
  letter-spacing: 0;
  font-size: 16px !important;
  text-decoration: line-through;
  font-family: "ZemestroStd-Light" !important;
  font-weight: 100;
}
.ProductPage .discountTag {
  height: 23px;
  font-size: 16px;
  color: #ea462f;
  text-align: center;
  letter-spacing: 0;
  font-family: "ZemestroStd-Regular" !important;
  font-weight: 100;
  background: #ffeff1 0 0 no-repeat padding-box;
}
