.ImagesCarousel {
  .ant-carousel {
    .slick-slider {
      .slick-list {
        .slick-track {
          .slick-slide {
            margin-right: 10px !important;
          }
        }
      }
    }
  }
}
body .thumbnail-slider {
  flex-flow: unset;
}
body .thumbnail-slider .slick-track {
  display: flex;
}
body .thumbnail-slider .slick-slide:not(.slick-current) {
  opacity: 0.3!important;
}